import React, {
  lazy,
  Suspense,
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import { useLocation } from "react-router-dom"

import Iframe from "../components/Iframe/index"

import CaseStudyModal from "../components/ThankYouModal/CaseStudyModal"
import MerchantModal from "../components/ThankYouModal/MerchantModal"
import ReferralModal from "../components/ThankYouModal/ReferralModal"

import MerchantIframe from "../components/MerchantIframe/index"
import Loader from "../components/Loader/Loader"

import Logo from "../images/slogo.svg"
import MenuOpener from "../images/mobile_menu_opener.png"
import { Helmet } from "react-helmet"

const Banner = lazy(() => import("../components/SubscriptionBanner/index"))
const PizzaSubscription = lazy(() =>
  import("../components/PizzaSubscription/index")
)
const SubscriptionPortFolio = lazy(() =>
  import("../components/SubscriptionPortFolio/index")
)
const PortfolioCalculator = lazy(() =>
  import("../components/PortfolioCalculator/index")
)
const Benefits = lazy(() => import("../components/SubscriptionBenefits/index"))
const FAQ = lazy(() => import("../components/FAQ/index"))
const Footer = lazy(() => import("../components/SubscriptionFooter/index"))

const Homepage = () => {
  const [queryParams, setQueryParams] = useState("")
  const [queryParamsWithOutHash, setQueryParamsWithOutHash] = useState("")

  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openIframePopup, setOpenIframePopup] = useState(false)
  const [openCaseStudyModal, setOpenCaseStudyModal] = useState(false)
  const [openMerchantModal, setOpenMerchantModal] = useState(false)
  const [openReferralModal, setOpenReferralModal] = useState(false)
  const [openMerchantIframe, setOpenMerchantIframe] = useState(false)

  const topRef = useRef(null)
  const whySubscriptionRef = useRef(null)
  const howItWorkRef = useRef(null)
  const benefitRef = useRef(null)
  const faqRef = useRef(null)

  const location = useLocation()

  useLayoutEffect(() => {
    const hash = window.location.hash
    const refsMap = {
      "#HOWITWORKS": howItWorkRef,
      "#WHYSUBSCRIPTIONS": whySubscriptionRef,
      "#BENEFITS": benefitRef,
      "#FAQ": faqRef,
    }

    const ref = refsMap[hash]
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    } else if (!hash && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  const loadIframe = () => {
    // Preconnect to the domain
    const preconnectLink = document.createElement("link")
    preconnectLink.rel = "preconnect"
    preconnectLink.href = "https://link.msgsndr.com"
    document.head.appendChild(preconnectLink)

    // Preload the script
    const preloadLink = document.createElement("link")
    preloadLink.rel = "preload"
    preloadLink.href = "https://link.msgsndr.com/js/form_embed.js"
    preloadLink.as = "script"
    document.head.appendChild(preloadLink)

    // Append the actual script tag to the document body
    const script = document.createElement("script")
    script.src = "https://link.msgsndr.com/js/form_embed.js"
    script.async = true
    document.body.appendChild(script)

    // Clean up function
    return () => {
      // Clean up the script and links when the popup is closed
      document.body.removeChild(script)
      document.head.removeChild(preconnectLink)
      document.head.removeChild(preloadLink)
    }
  }

  useEffect(() => {
    loadIframe()

    const hash = window.location.hash
    const url = window.location.href
    const params = checkParamsInUrl(url)
    const paramsWithOutHash = checkParamsInUrlWithOutHash(url)
    const paramsBookADemo = checkParamsInUrlWithOutHashBookADemo(url)

    setQueryParams(
      params
        ? `https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP${params}`
        : "https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP"
    )
    setQueryParamsWithOutHash(
      paramsWithOutHash
        ? `https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF${paramsWithOutHash}`
        : "https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF"
    )

    const referralModalPart = location.pathname.split("/").pop()
    setOpenIframePopup(
      params || referralModalPart === "book-demo" || paramsBookADemo
    )
    setOpenCaseStudyModal(referralModalPart === "casestudymodal")
    setOpenMerchantModal(referralModalPart === "merchantmodal")
    setOpenReferralModal(referralModalPart === "referralmodal")
    setOpenMerchantIframe(
      referralModalPart === "merchant-info-form" || paramsWithOutHash
    )
  }, [location])

  const checkParamsInUrl = useCallback((url) => {
    const hashIndex = url.indexOf("#")
    if (hashIndex !== -1) {
      const queryIndex = url.substring(hashIndex + 1).indexOf("?")
      if (queryIndex !== -1) {
        return url.substring(hashIndex + 1 + queryIndex)
      }
    }
    return ""
  }, [])

  const checkParamsInUrlWithOutHash = useCallback((url) => {
    const pathSegment = "/merchant-info-form"
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?")
      return queryIndex !== -1 ? url.substring(queryIndex) : ""
    }
    return ""
  }, [])

  const checkParamsInUrlWithOutHashBookADemo = useCallback((url) => {
    const pathSegment = "/book-demo"
    if (url.includes(pathSegment)) {
      const queryIndex = url.indexOf("?")
      return queryIndex !== -1 ? url.substring(queryIndex) : ""
    }
    return ""
  }, [])

  const handleScroll = useCallback((ref, id) => {
    setOpenMobileMenu(false)
    const navbarHeight = document.getElementById("navbar").offsetHeight + 800
    const element = document.getElementById(id)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - navbarHeight,
        behavior: "smooth",
      })
    }
  }, [])

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu)
  }

  const handleOpenIframe = () => {
    setOpenIframePopup(true)
  }

  const handleCloseIframePopup = () => {
    setOpenIframePopup(false)
  }

  const handleCloseMerchantIframe = () => {
    setOpenMerchantIframe(false)
  }

  const handleCloseCaseStudyModal = () => {
    setOpenCaseStudyModal(false)
  }

  const handleCloseMerchantModal = () => {
    setOpenMerchantModal(false)
  }

  const handleCloseReferralModal = () => {
    setOpenReferralModal(false)
  }

  return (
    <>
      <Helmet>
        <title>PizzaBox AI - Pizza Subscription Platform For Pizzerias</title>
        <meta
          name="description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <meta
          name="keywords"
          content="pizza subscription, pizzeria benefits, local pizza business, subscription
    revenue, customer loyalty, recurring income, Caesar Salad, Cheesecake, Cheeseburger Sub, Cheesesteak, Cookies, French onion soup, Fried Chicken, Pasta, Pierogies, Shrimp, Stromboli, Wings,Sicilian Pizza, New York Pizza, Veggie Pizza, Deep Dish Pizza, By the Slice, Gluten Free Pizza, Neapolitan Pizza, Tomato Pie, Grandma Pizza, Thin Crust Pizza,Stuffed Pizza, Thick Crust Pizza,
    Pizza subscriptions, ai pizza, pizza gpt, Pizza box, Pizza memberships, Pizza loyalty, Pizza loyalty programs, Pizza subscription implementation, Pizza subscription, PizzaBox Benefits, pizza subscription case study, pizza per week, weekly pizza, pizza redemption, yearly pizza subscription, customer retention"
        />
        <link rel="canonical" href="https://pizzabox.ai/" />
        <meta
          property="og:title"
          content="PizzaBox AI - Pizza Subscription Platform For Pizzerias"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <meta property="og:url" content="https://pizzabox.ai/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="PizzaBox AI - Pizza Subscription Platform For Pizzerias"
        />
        <meta name="twitter:url" content={`https://pizzabox.ai/`} />

        <meta
          name="twitter:description"
          content="Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            name: "PizzaBox AI",
            alternateName: "PizzaBox AI",
            url: "https://pizzabox.ai/",
            description:
              "Launch a pizza subscription with PizzaBox: raise zero-interest capital, ensure weekly sales, and build strong customer loyalty with the best pizza program.",
          })}
        </script>
      </Helmet>
      <Suspense
        fallback={
          <>
            <Loader />
          </>
        }
      >
        <div className="bg-[#F8F4E6]">
          <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
            <div className="px-5 md:px-8 xl:px-0">
              <div id="TOP" ref={topRef}></div>
              <div
                className="w-full bg-[#EAE4CE] fixed top-0 z-50 px-6 lg:px-0 right-0"
                id="navbar"
              >
                <div className="w-full md:container">
                  <nav className="pt-8">
                    <div className="py-2 flex flex-col xl:flex-row justify-between items-start">
                      <div className="text-2xl font-bold w-full xl:w-[37%] flex justify-between">
                        <img
                          src={Logo}
                          alt="Logo"
                          className="w-[70%] 2xl:w-[80%]"
                          width={426}
                          height={104}
                        />
                        <div className="xl:hidden">
                          <img
                            src={MenuOpener}
                            alt="menu opener"
                            className="w-12 sm:w-16 md:w-20 lg:w-28"
                            onClick={handleOpenMobileMenu}
                          />
                        </div>
                      </div>

                      <div
                        className={`w-full xl:w-[63%] mt-6 xl:flex transition-all duration-300 ease-in-out ${
                          openMobileMenu ? "" : "hidden"
                        }`}
                      >
                        <div className="w-full flex flex-col xl:flex-row bg-[#FFBD00] justify-between items-center gap-1 rounded-3xl xl:rounded-full">
                          <a
                            href="#HOWITWORKS"
                            className="relative py-3 sm:py-6 xl:py-0 pl-4 xl:pl-8 pr-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                            onClick={() =>
                              handleScroll(howItWorkRef, "HOWITWORKS")
                            }
                          >
                            HOW IT WORKS
                          </a>
                          <a
                            href="#WHYSUBSCRIPTIONS"
                            className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                            onClick={() =>
                              handleScroll(
                                whySubscriptionRef,
                                "WHYSUBSCRIPTIONS"
                              )
                            }
                          >
                            WHY SUBSCRIPTIONS
                          </a>
                          <a
                            href="#BENEFITS"
                            className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                            onClick={() => handleScroll(benefitRef, "BENEFITS")}
                          >
                            BENEFITS
                          </a>
                          <a
                            href="#FAQ"
                            className="py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                            onClick={() => handleScroll(faqRef, "FAQ")}
                          >
                            FAQ
                          </a>
                          <button
                            onClick={handleOpenIframe}
                            className="text-white py-3 sm:py-6 xl:py-2 bg-black font-semibold px-[40px] text-[24px] sm:text-[26px] xl:text-[22px] rounded-3xl xl:rounded-full w-full xl:w-64 text-center"
                          >
                            Book a demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="w-full md:container pt-24 md:pt-48 xl:pt-28">
                <Banner handleOpenPopup={handleOpenIframe} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE]">
          <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
            <div className="w-full md:container px-5 xl:px-0">
              <PizzaSubscription
                id="HOWITWORKS"
                ref={howItWorkRef}
                handleOpenPopup={handleOpenIframe}
              />
            </div>
          </div>
        </div>
        <div className="bg-[#F8F4E6]">
          <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
            <div className="w-full md:container px-5 xl:px-0">
              <SubscriptionPortFolio handleOpenPopup={handleOpenIframe} />
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE]">
          <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]]">
            <div className="w-full md:container px-5 xl:px-0">
              <PortfolioCalculator handleOpenPopup={handleOpenIframe} />
            </div>
          </div>
        </div>
        <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
          <div className="w-full md:container">
            <Benefits
              handleOpenPopup={handleOpenIframe}
              whySubId="WHYSUBSCRIPTIONS"
              beneId="BENEFITS"
              ref={{ whySubRef: whySubscriptionRef, beneRef: benefitRef }}
            />
          </div>
        </div>
        <div className="bg-[#000]">
          <div className="bg-white h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
            <div className="w-full md:container px-5 xl:px-0">
              <FAQ id="FAQ" ref={faqRef} />
            </div>
          </div>
        </div>
        <div className="bg-[#000] h-auto">
          <div className="w-full md:container">
            <Footer />
          </div>
        </div>

        <Iframe
          openIframePopup={openIframePopup}
          onClose={handleCloseIframePopup}
          queryParams={queryParams}
        />
        {openCaseStudyModal && (
          <CaseStudyModal
            open={openCaseStudyModal}
            onClose={handleCloseCaseStudyModal}
          />
        )}
        {openMerchantModal && (
          <MerchantModal
            open={openMerchantModal}
            onClose={handleCloseMerchantModal}
          />
        )}
        {openReferralModal && (
          <ReferralModal
            open={openReferralModal}
            onClose={handleCloseReferralModal}
          />
        )}
        {openMerchantIframe && (
          <MerchantIframe
            openIframePopup={openMerchantIframe}
            onClose={handleCloseMerchantIframe}
            queryParams={queryParamsWithOutHash}
          />
        )}
      </Suspense>
    </>
  )
}

export default Homepage
