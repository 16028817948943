import React from "react";
import FooterLogo from "../../images/footer/pizzaLogoFooter.svg";

import ReferralIframe from "../ReferalIframe/index";

const Index = () => {
  const [openReferralIframe, setOpenReferralIframe] = React.useState(false);

  const handleOpenReferralIframe = () => {
    setOpenReferralIframe(true);
  };

  const handleCloseReferralIframe = () => {
    setOpenReferralIframe(false);
  };

  return (
    <>
      <div className="pt-14 pb-12 sm:pt-12 sm:pb-32 lg:pt-12 md:pt-14">
        <div className="xl:w-full flex items-center justify-center lg:mb-16 mb-10">
          <button className="font-sfPro font-semibold bg-[#FBCD33] text-[#000000] py-2 px-[15px] lg:px-10 xl:px-8 rounded-full text-[18px] sm:text-[35px] lg:text-[52px] xl:text-[28px] xl:w-[430px] text-center xl:mt-12 mt-0 bottom-1" onClick={handleOpenReferralIframe}>
          Referral Program Signup
          </button>
        </div>
        <div className="flex xl:flex-row flex-col gap-5 items-center justify-between w-full">
          <div className="w-full xl:w-fit flex justify-center flex-col xl:gap-5 xl:justify-start">
            <img
              src={FooterLogo}
              alt="FooterLogo"
              className="w-[70%] xl:w-[100%] mx-auto"
              loading="lazy"
              width={694}
              height={120}
            />
            <p className="font-sfPro text-[16px] text-[#DCCFFF] hidden xl:block">
              Copyright © {new Date().getFullYear()}. All rights reserved by
              RestoGPT
            </p>
          </div>
          <div className="w-full xl:w-fit flex justify-center flex-col xl:gap-5 xl:justify-start mt-6 xl:mt-0">
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase underline text-center xl:text-left">
              <a href="mailto:subscriptions@pizzabox.ai">
                subscriptions@pizzabox.ai
              </a>
            </p>
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase text-center xl:text-left mt-6 xl:mt-0">
              SAN FRANCISCO, CA
            </p>
          </div>
          <div className="w-full xl:w-fit flex justify-center flex-col xl:gap-5 xl:justify-start">
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase underline text-center xl:text-left">
              <a href="press">NEWS AND PRESS</a>
            </p>
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase underline text-center xl:text-left mt-6 xl:mt-0">
              <a href="blog">BLOG</a>
            </p>
          </div>
          <div className="w-full xl:w-fit flex justify-center flex-col xl:gap-5 xl:justify-start">
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase underline text-center xl:text-left">
              <a href="https://restogpt.ai/term-and-condition" target="_blank" rel="noreferrer">
                TERMS AND CONDITIONS
              </a>
            </p>
            <p className="font-sfPro text-[16px] sm:text-[19px] text-[#fff] uppercase underline text-center xl:text-left mt-6 xl:mt-0">
              <a href="https://restogpt.ai/privacy-policy" target="_blank" rel="noreferrer">
                PRIVACY POLICY
              </a>
            </p>
          </div>
          <div className="w-full xl:hidden text-center">
            <p className="font-sfPro text-[16px] sm:text-[22px] text-[#DCCFFF] mt-10">
              Copyright © {new Date().getFullYear()}. All rights reserved by
              RestoGPT
            </p>
          </div>
        </div>
      </div>
      <ReferralIframe openIframePopup={openReferralIframe} onClose={handleCloseReferralIframe} />
    </>
  );
};

export default Index;
